.bottom-signup-container {
  .ant-pro-form-login-container {
    padding: 0;
  }
  .ant-pro-form-login-main {
    height: 100%;
    .ant-btn {
      margin-top: 24px;
    }
  }
}
