@font-face {
  font-family: "PingFang-SC";
  src: url("../../assets/fonts/NotoSansTC/PingFang-SC-Light.ttf")
    format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "PingFang-SC";
  src: url("../../assets/fonts/NotoSansTC/PingFang-SC-Regular.ttf")
    format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "PingFang-SC";
  src: url("../../assets/fonts/NotoSansTC/PingFang-SC-Medium.ttf")
    format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "PingFang-SC";
  src: url("../../assets/fonts/NotoSansTC/PingFang-SC-Bold.ttf")
    format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

body {
  font-family: PingFang-SC, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}
