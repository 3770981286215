.header {
  width: 100%;
  height: 54px;
  padding: 9px 12px;
  border-bottom: 1px solid var(--line1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  .leftContainer {
    height: 100%;
    display: flex;
    align-items: center;
    .header__left__hamburger {
      height: 24px;
      width: 24px;
      margin-right: 10px;
    }
    .logo {
      width: auto;
      height: 100%;
    }
  }
  .loginBtn {
    height: 36px;
    line-height: 36px;
    border-radius: 100px;
    padding: 0px 16px;
    background-color: var(--warning6);
    color: var(--text1);
    font-weight: 400;
    font-size: 15px;
  }
  .personPhoto {
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }
}
