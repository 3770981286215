.order-list {
  margin-top: 8px;
  height: calc(100% - var(--order-headerHeight));
  padding-bottom: 10px;
  overflow-y: scroll;
  padding-bottom: 50%;
  .adm-list-item {
    padding-left: 14px;
    .adm-list-item-content-main {
      padding: 8px 0;
    }
    .adm-list-item-content-arrow {
      color: var(--text4);
      width: 16px;
      height: 16px;
    }
  }
}
