.bottom-container {
  .ant-pro-form-login-container {
    padding: 0;
  }
  .ant-pro-form-login-main {
    height: 100%;
    .ant-form {
      height: 200px;
    }
  }
}
