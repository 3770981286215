.ant-form {
  .ant-result-icon {
    margin-bottom: 40px;
  }
  .ant-result-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin: 16px 0;
  }
  .ant-result-extra {
    margin-top: 40px;
  }
}
