.searchContainer {
  width: 100%;
  background-color: var(--white);
  position: relative;
  .topContainer {
    display: flex;
    align-items: center;
    padding: 16px 12px;
    .input {
      font-size: 14px;
      border-radius: 100px;
      height: 36px;
      background-color: var(--fill2);
      &::placeholder {
        color: var(--text2);
      }
    }
    .button {
      color: var(--brand6);
      font-size: 16px;
      font-weight: 400;
      margin-left: 12px;
      white-space: nowrap;
    }
  }
  .hotArea {
    width: 100%;
    height: 100vh;
    margin-top: -2px;
    background-color: var(--white);
    z-index: 100;
    position: absolute;
  }
}
