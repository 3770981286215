.orderNumber,
.orderUrl {
  text-decoration: underline;
}
.btn {
  background-color: var(--warning6);
  border-radius: 100px;
  color: var(--white);
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  padding: 7.5px 16px;
  width: 100%;
}
