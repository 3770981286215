.ant-form {
  height: calc(100% - var(--orderDrawer-stepHeight));
  .buy-sell-container {
    .ant-checkbox-wrapper {
      height: 16px;
      .ant-checkbox-inner {
        border-radius: 100%;
      }
    }
  }
  .describe-container {
    .ant-input-affix-wrapper {
      padding: 16px 12px;
      border: 0;
      flex-direction: column;
      height: var(--orderDrawer-describeHeight);
      &::before {
        display: none;
      }
      .ant-input {
        height: 44px;
        font-size: 16px;
      }
      .ant-input-suffix {
        align-self: flex-end;
      }
    }
  }
  .data-describe {
    .ant-input-affix-wrapper {
      height: 22px;
      border-color: var(--orderCardDescribe);
      padding-right: 4px;
      .ant-input {
        &::placeholder {
          font-size: 12px;
          line-height: 12px;
          color: var(--orderCardDescribe);
        }
      }
      .ant-input-suffix {
        font-size: 12px;
      }
    }
  }
  .data-price {
    .ant-input-number {
      border: 0;
      background-color: unset;
      .ant-input-number-input {
        height: 22px;
        color: var(--danger6);
        font-size: 20px;
        padding: 0;
      }
    }
  }
  .quota {
    .ant-input-number {
      width: 40px;
      border: 0;
      background-color: unset;
      .ant-input-number-input {
        width: 40px;
        font-size: 15px;
        padding: 0;
        text-align: center;
      }
      &:focus {
        border: 0;
      }
    }
  }
}
