.basic-drawer {
  .ant-drawer-header {
    padding: 14px 16px;
    height: 44px;
    .ant-drawer-close {
      padding: 0;
      margin: 0;
      width: 32px;
    }
    .ant-drawer-title {
      text-align: center;
      color: var(--text5);
      font-weight: 500;
      font-size: 17px;
    }
    .ant-drawer-extra {
      width: 32px;
      .ant-btn {
        font-weight: 400;
      }
    }
  }
  .ant-drawer-header-close-only {
    display: none;
  }
  .ant-drawer-body {
    padding: 8px 0 0 0;
    background-color: var(--homeBg);
    .ant-checkbox-wrapper {
      height: 16px;
      .ant-checkbox-inner {
        border-radius: 100%;
      }
    }
  }
}
