.container {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text5);
  font-weight: 400;
  font-size: 16px;
  background-color: var(--white);
  height: 54px;
  .rightContainer {
    display: flex;
    align-items: center;
    .content {
      color: var(--text3);
      margin: 0 8px;
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
