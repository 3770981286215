.order-drawer {
  .ant-drawer-header {
    padding: 14px 16px;
    height: 44px;
    .ant-drawer-close {
      padding: 0;
      margin: 0;
    }
    .ant-drawer-title {
      text-align: center;
      color: var(--text5);
      font-weight: 500;
      font-size: 17px;
    }
  }
  .ant-drawer-body {
    padding: 8px 0 0 0;
    background-color: var(--homeBg);
    overflow-y: hidden;
    .ant-steps {
      height: var(--orderDrawer-stepHeight);
      padding: 16px 0;
      .ant-steps-item {
        .ant-steps-item-container {
          .ant-steps-item-tail {
            border-color: var(--warning6);
          }
          .ant-steps-item-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--fill2);
            .ant-steps-icon {
              font-size: 14px;
              padding-top: 2px;
              .anticon-check {
                color: var(--warning6);
              }
            }
          }
          .ant-steps-item-content {
            margin-top: 5px;
            text-align: center;
            .ant-steps-item-title {
              color: var(--text3);
              font-weight: 400;
            }
          }
        }
      }
      .ant-steps-item-active {
        .ant-steps-item-container {
          .ant-steps-item-icon {
            background-color: var(--warning6);
            border-color: var(--warning6);
          }
          .ant-steps-item-content {
            margin-top: 5px;
            .ant-steps-item-title {
              color: var(--warning6);
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .ant-form-item {
    margin: 0;
  }
}
