.actionArea {
  width: 100%;
  padding: 20px 0;
  border-radius: 12px 12px 0px 0px;
  position: absolute;
  bottom: 0;
  background-color: var(--actionAreaBg);
  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .actionBtn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .actionBtnImg {
        width: 52px;
        height: 52px;
      }
      .actionBtnName {
        font-weight: 400;
        font-size: 12px;
        color: var(--text3);
      }
    }
  }
}
