.previewCard {
  position: absolute;
  display: none;
  z-index: 1000;
  &.show {
    display: unset;
  }
  .container {
    width: 100vw;
    height: 100vh;
    padding: 40% 10%;
    background-color: var(--previewBg);
    .card {
      width: 100%;
      height: auto;
      margin: 0 auto;
      animation: popup 0.5s;
    }
  }
}

@keyframes popup {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
