.order {
  display: flex;
  align-items: center;
  .cardList {
    height: 88px;
    width: 76px;
    display: flex;
    border-radius: 5px;
    .card {
      width: 6px;
      .cardImg {
        height: 100%;
        width: 64px;
        border-radius: 5px;
      }
    }
  }
  .data {
    margin-left: 16px;
    .orderNumber {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    .buySell {
      height: 28px;
      margin: 6px 0;
      .buySellImg {
        width: 28px;
        height: 28px;
      }
    }
    .cardQuota {
      display: flex;
      align-items: center;
      .quotaImg {
        margin-right: 5px;
        width: 24px;
        height: 24px;
      }
      .quotaValue {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
