.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .submitBtn {
    background-color: var(--white);
    border-top: 1px;
    border-color: var(--line1);
    padding: 8px 12px;
    width: 100%;
    height: 52px;
    margin-top: -52px;
    z-index: 100;
    .btnText {
      background-color: var(--warning6);
      border-radius: 100px;
      color: var(--white);
      font-size: 15px;
      font-weight: 400;
      text-align: center;
      padding: 7.5px 16px;
    }
  }
}
