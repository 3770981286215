.container {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text5);
  font-weight: 400;
  font-size: 16px;
  background-color: var(--white);
  height: 54px;
}
