.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .topContainer {
    background-image: url("../../../../../public/images/info_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    padding-bottom: 60px;
    .header {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 12px;
      .iconContainer {
        width: 32px;
        height: 32px;
        background-color: var(--alpha1);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        .notice {
          width: 24px;
          height: 24px;
        }
      }
    }
    .info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar {
        width: 80px;
        height: 80px;
        border-radius: 100%;
      }
      .name {
        margin-top: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: var(--white);
      }
      .userId {
        margin-top: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: var(--white);
        .userIdValue {
          color: var(--text6);
          margin-left: 4px;
        }
      }
    }
  }
  .bottomContainer {
    flex-grow: 1;
    .itemContainer {
      width: 100%;
      .itemArea {
        padding: 0 24px;
        .item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .title {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }
  }
  .logoutContainer {
    width: 100%;
    padding: 16px 24px;
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        color: var(--danger6);
      }
    }
  }
}
